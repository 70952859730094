<template>
  <div style="margin: 20px">
    <div class="flex justify-between items-center">
      <p class="font-bold text-lg">Student Detail</p>
    </div>
    <div class="mt-5 student-info mb-8">
      <div class="flex items-center">
        <img
          class="student-avatar"
          :src="
            currentStudent.student_info.avatar_url ||
            'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
          "
        />
        <div class="flex-1 student-name">
          {{
            currentStudent.student_info.student_lastName +
            currentStudent.student_info.student_givenName
          }}
        </div>
      </div>
      <div class="flex flex-wrap mt-2">
        <div class="w-1/2 mt-3 info">
          Email：{{ currentStudent.student_info.student_email || "-" }}
        </div>
        <div class="w-1/2 mt-3 info">
          Mobile：{{ currentStudent.student_info.student_mobile }}
        </div>
        <div class="w-1/2 mt-3 info">
          Country：{{ currentStudent.account_info.account_country }}
        </div>
        <div class="w-1/2 mt-3 info">
          Year of High School Graduation：{{
            currentStudent.student_info.student_graduation
          }}
        </div>
      </div>
    </div>
    <div>
      <p class="font-bold text-lg">Entry List</p>
      <el-empty
        v-if="entryList.length === 0"
        description="No Data Yet"
      ></el-empty>
      <div class="flex flex-wrap gap-4 mt-5" v-else>
        <div
          class="activity-container p-6 bg-white flex justify-between"
          v-for="(info, index) in entryList"
          style="cursor: pointer"
          @click="viewEntry(info)"
          :key="index"
        >
          <div class="flex" style="width: 100%">
            <div class="sort font-bold mr-6">{{ index + 1 }}</div>
            <div class="activity-info" style="width: 100%; flex: 1">
              <p class="activity-name text-base font-bold mb-4">
                {{ info.title }}
              </p>
              <div class="activity-time text-sm font-bold mb-2">
                {{ info.type.name + " | " + formatEntryType(info.entry_type) }}
              </div>
              <div class="activity-desc">
                <p class="text-sm font-bold">
                  {{ info.intro }}
                </p>
              </div>
              <div
                style="
                  display: flex;
                  width: 100%;
                  align-items: center;
                  margin-top: 10px;
                "
              >
                <div class="text-sm font-bold" style="color: #999999">
                  Entered on
                  {{ $dayjs(info.create_time * 1000).format("YYYY/MM/DD") }}
                </div>
                <div class="text-sm font-bold" style="margin-left: 20px">
                  <p style="color: #999999">
                    <span>Status：</span
                    ><span style="color: #999999">{{
                      info.open_status == "YES" ? "Submitted" : "Under Editing"
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
              "
            >
              <el-button
                style="width: 105px"
                type="primary"
                size="small"
                @click.stop="handleRecommend(info._id.$id)"
                >Recommend</el-button
              >
              <el-button
                v-if="checkShowCertView(info)"
                style="width: 105px; margin-top: 15px"
                type="success"
                size="small"
                @click.stop="gotoShowResult(info)"
                >Result</el-button
              >
              <el-button
                v-if="checkShowCertView(info)"
                type="warning"
                size="small"
                style="margin-top: 15px; width: 105px"
                @click.stop="gotoShowCert(info)"
              >
                Certificate
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="recommendDialogVisible" title="Teacher Recommend">
      <el-form>
        <el-form-item label="Recommend Information:">
          <el-input type="textarea" v-model="recommendInfo" />
        </el-form-item>
        <el-form-item label="Recommend File:">
          <el-input :value="attachmentUrl" disabled />
          <el-upload
            class="upload-demo mt-2"
            ref="upload"
            action="#"
            :limit="1"
            :before-upload="beforeAttachUpload"
          >
            <el-button slot="trigger" size="small" type="primary"
              >Select File
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            recommendDialogVisible = false;
            attachmentUrl = '';
            entryId = '';
            recommendInfo = '';
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="onConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addContactRecommend,
  getApplicationsByAdvisor,
  getSingaporeQiniuToken,
  getReviewEntriesByUserId,
} from "../../api/eae";
import { ENTRY_TYPES, getAccountId } from "../../utils/store";
import * as qiniu from "qiniu-js";

export default {
  data() {
    return {
      currentStudent: {
        student_info: {
          student_lastName: "",
          student_lastName: "",
          avatar_url: "",
          student_email: "",
          student_mobile: "",
          student_graduation: "",
        },
        account_info: {
          account_country: "",
        },
      },
      entryList: [],
      recommendDialogVisible: false,
      attachmentUrl: "",
      recommendInfo: "",
      entryId: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    viewEntry(row) {
      this.$router.push({
        path: "entryEdit",
        query: {
          id: row._id.$id,
        },
      });
    },

    checkShowCertView(info) {
      let showCertView = false;
      let publish_time = info.type.certificate_publish_time;
      if (publish_time) {
        let current_time = parseInt(new Date().getTime() / 1000);
        if (publish_time > current_time) {
        } else {
          showCertView = true;
        }
      }
      return showCertView;
    },
    gotoShowResult(info) {
      this.$router.push({
        path: "/home/score",
        query: {
          id: info._id.$id,
        },
      });
    },
    gotoShowCert(info) {
      let url = this.$router.resolve({
        path: "/certificate",
        query: {
          id: info._id.$id,
        },
      });
      window.open(url.href, "_blank");
    },

    formatEntryType(entry_type) {
      if (entry_type == "team_story" || entry_type == "indie_story") {
        return 'Storyline'

      } else if (entry_type == "team_exp" || entry_type == "indie_exp") {
        return 'Single Activity'
      }
      return ENTRY_TYPES[entry_type];
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getSingaporeQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
    async beforeAttachUpload(file) {
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            this.attachmentUrl =
              "https://ap.learningfirst.tech/" + res.key;
          },
        };
        this.observable.subscribe(observer);
      } else {
      }
    },
    handleRecommend(id) {
      this.recommendDialogVisible = true;
      this.entryId = id;
    },
    async onConfirm() {
      if (!this.recommendInfo) {
        this.$message.warning("Please input recommend information");
      }
      try {
        const res = await addContactRecommend({
          contact_id: getAccountId(),
          student_id: this.$route.query.id,
          entry_id: this.entryId,
          recommend_info: this.recommendInfo,
          recommend_url: this.attachmentUrl,
        });
        this.recommendDialogVisible = false;
        this.attachmentUrl = "";
        this.entryId = "";
        this.recommendInfo = "";
        this.$message.success("Recommend successful");
      } catch (error) {}
    },
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getApplicationsByAdvisor({ account_id: getAccountId() }).then((res) => {
        if (res.data.code === 0) {
          this.currentStudent = res.data.data.find(
            (item) => item.student_info._id.$id === this.$route.query.id
          );
          getReviewEntriesByUserId(this.$route.query.id)
            .then((res) => {
              console.log(res.data);
              this.entryList = res.data.data;
            })
            .finally(() => loading.close());
        }
      });
    },
  },
};
</script>

<style lang="scss">
.student-info {
  padding: 24px 30px;
  background: white;

  .info {
    font-size: 17px;
    font-weight: 400;
    color: #333333;
  }

  .student-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 18px;
  }

  .student-name {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
  }
}

.activity-container {
  width: calc(50% - 8px);

  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .activity-info {
    .activity-name {
      color: #333333;
    }

    .activity-time {
      color: var(--c-primary);

      span {
        color: #999999;
      }

      .success {
        color: #ff8900;
      }

      p {
        margin-right: 45px;
      }
    }

    .activity-location {
      color: #ff8900;

      span {
        color: #999999;
      }

      p {
        margin-right: 60px;
      }
    }

    .activity-desc {
      color: #666666;
    }
  }
}
</style>
